import headerImage from '../../assets/img/team/header-img.png';
import goalImage from '../../assets/img/team/goal-img.png';
import globeImage from '../../assets/img/team/globe-img.png';
import multipleImage from '../../assets/img/team/multiple-img.png';
import rotateImage from '../../assets/img/team/rotate-img.png';
import remoteWorkImage from '../../assets/img/team/remote-work-img.png';
import flexibleHoursImage from '../../assets/img/team/flexible-hours-img.png';
import meetupsImage from '../../assets/img/team/meetups-img.png';
import learningImage from '../../assets/img/team/learning-img.png';
import collabImage from '../../assets/img/team/collab-img.png';
import staffImage from '../../assets/img/staff-img.png';
import aimeeImage from '../../assets/img/team/team-profile-img/aimee.jpg';
import aimeePetImage from '../../assets/img/team/team-profile-img/aimee-pet.jpg';
import aimeeWpImage from '../../assets/img/team/team-profile-img/aimee-wp.jpg';
import annaImage from '../../assets/img/team/team-profile-img/anna.jpg';
import annaPetImage from '../../assets/img/team/team-profile-img/anna-pet.jpg';
import annaWpImage from '../../assets/img/team/team-profile-img/anna-wp.jpg';
import charlotteImage from '../../assets/img/team/team-profile-img/charlotte.jpg';
import charlotteWpImage from '../../assets/img/team/team-profile-img/charlotte-wp.jpg';
import charlottePetImage from '../../assets/img/team/team-profile-img/charlotte-pet.jpg';
import chloeImage from '../../assets/img/team/team-profile-img/chloe.jpg';
import chloeWpImage from '../../assets/img/team/team-profile-img/chloe-wp.jpg';
import chrisImage from '../../assets/img/team/team-profile-img/chris.jpg';
import chrisWpImage from '../../assets/img/team/team-profile-img/chris-wp.jpg';
import darraghImage from '../../assets/img/team/team-profile-img/darragh.jpg';
import darraghWpImage from '../../assets/img/team/team-profile-img/darragh-wp.jpg';
import byronImage from '../../assets/img/team/team-profile-img/byron.jpg';
import byronWpImage from '../../assets/img/team/team-profile-img/byron-wp.jpg';
import hannahImage from '../../assets/img/team/team-profile-img/hannah.jpg';
import hannahWpImage from '../../assets/img/team/team-profile-img/hannah-wp.jpg';
import hannahPetImage from '../../assets/img/team/team-profile-img/hannah-pet.jpg';
import ilonaImage from '../../assets/img/team/team-profile-img/ilona.jpg';
import ilonaWpImage from '../../assets/img/team/team-profile-img/ilona-wp.jpg';
import ilonaPetImage from '../../assets/img/team/team-profile-img/ilona-pet.jpg';
import josephImage from '../../assets/img/team/team-profile-img/joseph.jpg';
import josephWpImage from '../../assets/img/team/team-profile-img/joseph-wp.jpg';
import josephPetImage from '../../assets/img/team/team-profile-img/joseph-pet.jpg';
import julietImage from '../../assets/img/team/team-profile-img/juliet.jpg';
import julietWpImage from '../../assets/img/team/team-profile-img/juliet-wp.jpg';
import julietPetImage from '../../assets/img/team/team-profile-img/juliet-pet.jpg';
import louisImage from '../../assets/img/team/team-profile-img/louis.jpg';
import louisWpImage from '../../assets/img/team/team-profile-img/louis-wp.jpg';
import mehmetImage from '../../assets/img/team/team-profile-img/mehmet.jpg';
import mehmetWpImage from '../../assets/img/team/team-profile-img/mehmet-wp.jpg';
import mehmetPetImage from '../../assets/img/team/team-profile-img/mehmet-pet.jpg';
import raaheelImage from '../../assets/img/team/team-profile-img/raaheel.jpg';
import raaheelWpImage from '../../assets/img/team/team-profile-img/raaheel-wp.jpg';
import raaheelPetImage from '../../assets/img/team/team-profile-img/raaheel-pet.jpg';
import reenaImage from '../../assets/img/team/team-profile-img/reena.jpg';
import reenaWpImage from '../../assets/img/team/team-profile-img/reena-wp.jpg';
import reenaPetImage from '../../assets/img/team/team-profile-img/reena-pet.jpg';
import robImage from '../../assets/img/team/team-profile-img/rob.jpg';
import robWpImage from '../../assets/img/team/team-profile-img/rob-wp.jpg';
import samImage from '../../assets/img/team/team-profile-img/sam.jpg';
import samWpImage from '../../assets/img/team/team-profile-img/sam-wp.jpg';
import sarahImage from '../../assets/img/team/team-profile-img/sarah.jpg';
import sarahWpImage from '../../assets/img/team/team-profile-img/sarah-wp.jpg';
import sarahPetImage from '../../assets/img/team/team-profile-img/sarah-pet.jpg';

class TeamController {

    constructor() {
        this.name = 'team';
    }

    initilaizeImages() {
        this.headerImage = headerImage;

        this.goalImage = goalImage;
        this.globeImage = globeImage;
        this.multipleImage = multipleImage;
        this.rotateImage = rotateImage;

        this.remoteWorkImage = remoteWorkImage;
        this.flexibleHoursImage = flexibleHoursImage;
        this.meetupsImage = meetupsImage;
        this.learningImage = learningImage;
        this.collabImage = collabImage;

        this.staffImage = staffImage;

        this.data = [
            {
                image: aimeeImage,
                name: 'Aimee Fearns',
                title: 'Community Manager',
                bio: 'A First Class Geography Graduate with a love for research and human insights. In my spare time you’ll find me doing puzzles or attempting to bake anything chocolaty. A hard core netflix binger, who loves watching true crime documentaries and going out with friends.',
                wpImage: aimeeWpImage,
                wpCaption: 'Living on the coast of Essex has its advantages… I just wish I lived closer to the mountains.',
                petImage: aimeePetImage,
                petCaption: 'We have 5 fish that live in an oil barrel in the garden, mine is called Jaffa Cake.',
            },
            {
                image: annaImage,
                name: 'Anna Hablak',
                title: 'Business Development Manager',
                bio: 'A Slovak-Canadian living in the UK. Fascinated by how people think. Lover of a well designed space. Foodie. Infinite explorer. Bookworm.',
                wpImage: annaWpImage,
                wpCaption: 'Living my best life in France where we lived for 6 months during one of the many COVID lockdown #remoteworkingperks',
                petImage: annaPetImage,
                petCaption: 'This is Her Royal Fluffiness Queen Chloe, and Master Leaf chaser. She always brightens my day!',
            },
            {
                image: byronImage,
                name: 'Byron Nel Smit',
                title: 'Senior Consultant',
                bio: 'Research psychologist committed to the endless pursuit of truth and knowledge. Fitness enthusiast, avid gamer, and proud rugby supporter. Go Bokke! Did I mention I’m South African?',
                wpImage: byronWpImage,
                wpCaption: 'Ballsbridge, Dublin. While not known for its year-round sunshine, there’s no better place to get an ice-cold pint of Guinness. ',
            },
            {
                image: charlotteImage,
                name: 'Charlotte Cullen',
                title: 'Community Manager',
                bio: 'Book lover, tote-bag enthusiast, and eco-friendly advocate! Likes comedy, music and spending time with friends and family. Interested in anything and everything psychology!',
                wpImage: charlotteWpImage,
                wpCaption: 'My home office (in my bedroom!)',
                petImage: charlottePetImage,
                petCaption: 'Alfie, my nan’s dog who often visits me in my ‘office’',
            },
            {
                image: chloeImage,
                name: 'Chloe Allen',
                title: 'Community Manager',
                bio: 'Passionate about understanding human behaviour. Lover of swing dance, art and travel.',
                wpImage: chloeWpImage,
                wpCaption: 'West London with three of my friends taking turns to make cups of tea!',
            },
            {
                image: chrisImage,
                name: 'Chris Calitz',
                title: 'Co-Founder',
                bio: 'Scuba diving, braai loving, technology enthusiast with an entrepreneurial twist. Global citizen with a South African home. Enjoys blowing off steam with some clay pigeon shooting.',
                wpImage: chrisWpImage,
                wpCaption: 'My favourite location apart from my home office to get work done.',
            },
            {
                image: darraghImage,
                name: 'Darragh McHenry',
                title: 'Senior Consultant',
                bio: 'A native of Ireland currently living in Barcelona who\'s first love is Sociology. very partial to cooking, plants, sports, chess but especially talking to people (preferably over a coffee). I have the world\'s largest collection of woolly jumpers and a tendency to over-exaggerate.',
                wpImage: darraghWpImage,
                wpCaption: 'This is the view from my desk and one of the perks of living in central Barcelona. You can pop out for a walk at lunch or take in the view with a morning coffee.',
            },
            {
                image: hannahImage,
                name: 'Hannah McDonald',
                title: 'Community Manager',
                bio: 'Human geographer with a constant desire to learn new things. Passionate about running, sustainable fashion, and noodles. Good taste in books. Bad taste in music.',
                wpImage: hannahWpImage,
                wpCaption: 'This is where the work gets done. Perks of working remotely: a bright pink office!',
                petImage: hannahPetImage,
                petCaption: 'Meet Lola! She’s very professional (loves to shake hands) and an excellent colleague. Struggles with replying to emails though.',
            },
            {
                image: ilonaImage,
                name: 'Ilona Hotopilo',
                title: 'Senior Consultant',
                bio: 'Ukrainian, born and raised by the seaside. Food-driven traveler who can teach you how to cook the best borshch. Fascinated by ancient stuff. Loves a good advertisement. The person of a random artistic hobby.',
                wpImage: ilonaWpImage,
                wpCaption: 'Perks of living in the Kyiv city center - you can break up the workday and take a long lunchtime walk!',
                petImage: ilonaPetImage,
                petCaption: 'Meet Bender, a lovely 14-year-old meow-producer, feet-grabber, and weird sleeping position-maker.',
            },
            {
                image: josephImage,
                name: 'Joseph Gill',
                title: 'Tech Team',
                bio: 'Long time tech enthusiast working as a Developer with additional experience in Multi Unit Management. An American who has spent the past 4 years living in and exploring Europe and the UK. Enjoys cooking, gaming, traveling, and hiking with his loving wife and adorable dog.',
                wpImage: josephWpImage,
                wpCaption: 'You can never have enough screens to work with! My work station where we live, just outside Chester.',
                petImage: josephPetImage,
                petCaption: 'Zoey the Pomskie. Food loving ball of fluff that has traveled all over the world with her loving parents.',
            },
            {
                image: julietImage,
                name: 'Juliet Pascall',
                title: 'Co-Founder and Managing Director',
                bio: 'Psychologist. Self taught entrepreneur. Passionate about merging human and digital. Diagram and stationery junkie. Runner. Horse Rider. Coffee snob. Zimbabwean.',
                wpImage: julietWpImage,
                wpCaption: 'Cape Town, South Africa - my office for most of December.',
                petImage: julietPetImage,
                petCaption: 'Meet Savvy, a warmblood with attitude. He keeps me humble!',
            },
            {
                image: louisImage,
                name: 'Louis Russell',
                title: 'Community Manager',
                bio: 'London born Sociology graduate. Passion for qualitative insights and human thinking. Spare time includes PS5, staying active and socialising with friends. Avid chocolate lover.',
                wpImage: louisWpImage,
                wpCaption: 'My home office! Based in London.',
            },
            {
                image: mehmetImage,
                name: 'Mehmet Omercik',
                title: 'Tech Team',
                bio: 'An Astrophysics Graduate who has now switched to Software Developing. Obsessed with learning about absolutely everything! This includes but is not limited to History, Culture and Art. Loves travelling, exploring nature on long walks and listening to music of all genres. Extremely interested in mixed martial arts.',
                wpImage: mehmetWpImage,
                wpCaption: 'This is where all the magic happens! All of your problems get solved on these screens :)',
                petImage: mehmetPetImage,
                petCaption: 'This is but one of many parks I can go to if I need to escape my screen. Beckenham Place Park in London.',
            },
            {
                image: raaheelImage,
                name: 'Raaheel Husain',
                title: 'Senior Consultant',
                bio: 'Multilingual insight strategist. Ex-participant. Traveller. Musician and world music lover. Mario Kart enthusiast. Makes a mean Pad Thai.',
                wpImage: raaheelWpImage,
                wpCaption: 'Give me a window to work next to and I’ll be happy!',
                petImage: raaheelPetImage,
                petCaption: 'Archie - A good boy who enjoys treats, walkies and chasing pigeons.',
            },
            {
                image: reenaImage,
                name: 'Reena Nemchand',
                title: 'Associate Director',
                bio: 'Business thinker, entrepreneur, property investor. Living a balanced and positive life. A meditator who quietly blogs all things motivation for women. Loves family, hot sauce, Spotify and laughing!',
                wpImage: reenaWpImage,
                wpCaption: 'I love a positive and productive space! My desk is perfect for me to zone in and get stuff done',
                petImage: reenaPetImage,
                petCaption: 'Meet Mali - a charismatic, acrobatic Bengal',
            },
            {
                image: robImage,
                name: 'Rob Myers',
                title: 'Tech Team',
                bio: 'Tech enthusiast through and through. Completed a PhD in Organic Electronics. Enjoys a good pun. Supports Liverpool Football Club. Fun loving. Adores Go-Karting. Competitive. Gamer. Enjoys running/cycling.',
                wpImage: robWpImage,
                wpCaption: 'My setup at home in Liverpool.',
            },
            {
                image: samImage,
                name: 'Samantha Okoh',
                title: 'Office Manager',
                bio: 'Light-hearted by nature. Loves dogs and days out by the beach. Music makes me happy. A rare breed who enjoys admin. I also live for the Summer.',
                wpImage: samWpImage,
                wpCaption: 'Golders Hill Park in Spring',
            },
            {
                image: sarahImage,
                name: 'Sarah Nitting-Fulin',
                title: 'Director',
                bio: 'Good listener and good talker (in 4 languages!). Fascinated by consumer psychology, marketing and everything neuroscience-y. French-Italian (with a bit of Irish and Spanish influence) strong minded mamma. ‘Work hard, play hard’ type person but ‘play hard’ involves wrestling a toddler off the top of the slides.',
                wpImage: sarahWpImage,
                wpCaption: 'My living room- equally close enough to the coffee machine and the fridge!',
                petImage: sarahPetImage,
                petCaption: 'Peppy the penguin, or, as we call him at home ‘Peppy, le pingouin’. He’s very low maintenance!',
            },
        ];
    }

    initMobileTouchListener() {
        $('.carousel-inner')
            .on('touchstart', function (e) {
                e.preventDefault();
                $(this)
                    .toggleClass('show-caption ');
            });
    }

    $onInit() {
        this.initilaizeImages();
        this.initMobileTouchListener();
    }
}

export default TeamController;
